import { round } from 'lodash';

export const currency = (value, allowedDecimal = 2) => {
  if (!value) {
    return '0';
  }

  const decimalCount = Number(value) % 1 == 0 ? 0 : allowedDecimal;
  const valueString = round(Number(value), decimalCount).toString().replace('.', ',');

  return valueString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const currencyNoDecimal = (value) => {
  if (!value) {
    return '0';
  }

  const roundedValue = Math.round(Number(value));
  const valueString = roundedValue.toString().replace('.', ',');

  return valueString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const currencyFormatter = (value, allowedDecimal = 2) => {
  if (!value) {
    return '0';
  }

  const decimalCount = Number(value) % 1 == 0 ? 0 : allowedDecimal;
  const valueString = round(Number(value), decimalCount);

  return (
    valueString
      .toString()
      .replace('.', ',')
      // .replace(/^0+/, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      .replace(/[A-Za-z]/g, '')
  );
};
